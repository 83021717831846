import { Box, Stack, Typography, useTheme } from "@mui/material";
import Image, { StaticImageData } from "next/legacy/image";

export const HomePageHeader = (props: {
  icon: StaticImageData;
  image: StaticImageData;
  text: string;
}) => {
  const theme = useTheme();
  const colors = theme.palette;

  return (
    <Box
      sx={{
        background: `linear-gradient(to top left, ${colors.grey[900]}, ${colors.grey[700]})`,
      }}
      borderRadius={"8px 8px 0px 0px"}
      height={200}
      overflow="hidden"
      display="flex"
      position="relative"
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        flex={1}
        position="relative"
      >
        {/*Icon*/}
        <Box display="flex" ml={5} minHeight={120}>
          <Image
            src={props.icon}
            objectFit="fill"
            alt={"SimIcon"}
            width={120}
            height={120}
          />
        </Box>

        {/*Verbiage*/}
        <Typography
          display="flex"
          fontSize="3em"
          fontWeight={700}
          color={colors.grey[600]}
          sx={{
            background: `linear-gradient(to top right, rgba(52, 58, 63, 0.4), rgba(18, 22, 25, 0.4))`,
          }}
          pl={1}
          pr={1.5}
          ml={3}
          whiteSpace={"nowrap"}
          zIndex={1}
        >
          {props.text.toUpperCase()}
        </Typography>
      </Stack>

      {/*Football image*/}
      <Box
        display="flex"
        position="absolute"
        right={0}
        zIndex={0}
        sx={{ opacity: 0.6 }}
      >
        <Image
          src={props.image}
          alt={"SimFootball"}
          width={300}
          height={200}
        />
      </Box>
    </Box>
  );
};
