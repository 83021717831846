export interface IConfig {
  environment: {
    name: string;
  };
  services: {
    url: string;
  };
  azureDirectory: {
    clientId: string;
    tenantId: string;
    customerScopes: string[];
  };
}

export function lookupConfigByDomain(url?: string): IConfig {
  if (!url) {
    return defaultConfigurationContext;
  }
  return (
    configRecords.find((k) => k.domain.exec(url))?.config ||
    defaultConfigurationContext
  );
}

// Only used when there is no provider, which is an unsupported scenario
export const defaultConfigurationContext: IConfig = {
  environment: {
    name: "the-neutral-zone",
  },
  services: {
    url: "https://localhost",
  },
  azureDirectory: {
    clientId: "invalid-config-no-provider",
    tenantId: "invalid-config-no-provider",
    customerScopes: ["invalid-config-no-provider"],
  },
};

export const configRecords: { domain: RegExp; config: IConfig }[] = [
  {
    domain: /^http:\/\/localhost:3000/,
    config: {
      environment: {
        name: "dev-local",
      },
      services: {
        url:
          process.env.NEXT_PUBLIC_SERVICES_OVERRIDE ||
          "https://internal.api.development.sumersports.com",
      },
      azureDirectory: {
        clientId: "5bf48db1-23ab-4cc3-b63d-bbd84874cd75",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: ["api://internal-dev.sumersports.com/readwrite"],
      },
    },
  },
  {
    domain: /^http:\/\/localhost:3001/,
    config: {
      environment: {
        name: "cypress-e2e",
      },
      services: {
        url: "https://internal.api.development.sumersports.com",
      },
      azureDirectory: {
        clientId: "5bf48db1-23ab-4cc3-b63d-bbd84874cd756",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: ["api://internal-dev.sumersports.com/readwrite"],
      },
    },
  },
  {
    domain: /^https:\/\/marval-[a-zA-Z0-9\-]{1,24}-sumersports\.vercel\.app/,
    config: {
      environment: {
        name: "dev-vercel-branch",
      },
      services: {
        url: "https://internal.api.development.sumersports.com",
      },
      azureDirectory: {
        clientId: "5bf48db1-23ab-4cc3-b63d-bbd84874cd75",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: ["api://internal-dev.sumersports.com/readwrite"],
      },
    },
  },
  {
    domain: /^https:\/\/internal\.marval-dev\.sumersports\.com/,
    config: {
      environment: {
        name: "dev-internal",
      },
      services: {
        url: "https://internal.api.development.sumersports.com",
      },
      azureDirectory: {
        clientId: "5bf48db1-23ab-4cc3-b63d-bbd84874cd75",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: ["api://internal-dev.sumersports.com/readwrite"],
      },
    },
  },
  {
    domain: /^https:\/\/internal\.marval\.sumersports\.com/,
    config: {
      environment: {
        name: "internal",
      },
      services: {
        url: "https://internal.api.production.sumersports.com",
      },
      azureDirectory: {
        clientId: "882fb142-42e2-4b85-858d-ec39e24325fe",
        tenantId: "f748cc0e-f9ac-42d4-bcd2-59317235ab77",
        customerScopes: ["api://internal-prd.sumersports.com/readwrite"],
      },
    },
  },
];

// Pre-render is done using the default config (at build time)
// client runtime will load the dynamic config envs based on the domain
// the site is currently hosted at
let runtimeConfig = defaultConfigurationContext;
if (typeof window !== "undefined") {
  runtimeConfig = lookupConfigByDomain(window.location.toString());
}

export const clientConfig = Object.freeze(runtimeConfig);

export const CDN_PATH = "https://cdn.sumersports.com/assets";

export const MUI_LICENSE =
  "bd5085adf561a8e1a303e9b6d54a5747Tz05MTAzOCxFPTE3NDc5NDI3MjEwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";


