import _ from "lodash";
import { PermissionFragment } from "../graphql/generated/graphql";

export enum PermissionEnum {
  PermissionContractRead = "Permissions.Contracts.Read",
  PermissionContractWrite = "Permissions.Contracts.Write",
  PermissionPlayerWrite = "Permissions.Players.Write",
  PermissionEvalAllRead = "Permissions.Evaluations.All.Read",
  PermissionEvalMineRead = "Permissions.Evaluations.Mine.Read",
  PermissionEvalWrite = "Permissions.Evaluations.Write",
  PermissionEvalGameWrite = "Permissions.Evaluations.Game.Write",
  PermissionEvalAnyWrite = "Permissions.Evaluations.Any.Write",
  PermissionEvalDelete = "Permissions.Evaluations.Delete",
  PermissionEvalReadSumerGrade = "Permissions.Evaluations.ReadSumerGrade",
  PermissionEvalStatusFinalWrite = "Permissions.Evaluations.Status.Final.Write",
  PermissionUsersWrite = "Permissions.Users.Write",
  PermissionScoutAssignmentsWrite = "Permissions.ScoutAssignments.Write",
}

export function hasPermission(
  permissions: PermissionFragment[] | undefined,
  permission: string
): boolean {
  if (permissions == undefined) return false;
  const hasPerm = _.some(permissions, function (perm) {
    return perm.name == permission;
  });

  return hasPerm;
}
