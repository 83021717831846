"use client";

import React, { ReactNode, useEffect } from "react";
import { MsalProvider } from "@azure/msal-react";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  LogLevel
} from "@azure/msal-browser";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/nextjs";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  split,
} from "@apollo/client";

import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { mergePageInfoQuery } from "../utils/pagination";
import { clientConfig, MUI_LICENSE } from "../config/config";
import { PageContainer } from "../components/design/PageContainer";
import { ThemeProvider } from "@mui/material/styles";
import { setThemeForUser } from "../utils/clubs";
import { LicenseInfo } from "@mui/x-license-pro";
import { createClient } from "graphql-ws";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { IntercomProvider } from "react-use-intercom";

LicenseInfo.setLicenseKey(MUI_LICENSE);
const INTERCOM_APP_ID = "ax4zvxtv";

// Client-side cache, shared for the whole session of the user in the browser.
// see https://github.com/mui/material-ui/tree/7bddc86c9f658142841ac3a202d9373ac95343bf/examples/nextjs/pages
const clientSideEmotionCache = createCache({ key: "css", prepend: true });

const httpLink = createHttpLink({
  uri: `${clientConfig.services.url}/gateway-schema-internal/graphql`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = (
    await msalInstance.acquireTokenSilent({
      scopes: clientConfig.azureDirectory.customerScopes,
    })
  ).accessToken;
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const wsLink =
  typeof window !== "undefined"
    ? new GraphQLWsLink(
        createClient({
          url: `${clientConfig.services.url.replace(
            "https://",
            "wss://"
          )}/marval/graphql-ws`,
          connectionParams: async () => {
            const token = await msalInstance.acquireTokenSilent({
              scopes: clientConfig.azureDirectory.customerScopes,
            });
            return {
              Authorization: `Bearer ${token.accessToken}`,
            };
          },
        })
      )
    : null;

const splitLink = wsLink
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      authLink.concat(httpLink)
    )
  : authLink.concat(httpLink);

// TODO: Followup for https://github.com/aws-amplify/amplify-ui/issues/1780
// A React or NJS bug is causing build to fail for some rare uses of hooks
// ApolloGQL can trigger this
const client = new ApolloClient({
  defaultOptions: {
    query: {
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  },
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Evaluation: {
        fields: {
          fits: {
            // Avoids a warning that apollo may not have correctly updated this array
            // tldr: it has
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing: any[], incoming: any[]) {
              return incoming;
            },
          },
          alerts: {
            // Avoids a warning that apollo may not have correctly updated this array
            // tldr: it has
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing: any[], incoming: any[]) {
              return incoming;
            },
          },
          clubFits: {
            // Avoids a warning that apollo may not have correctly updated this array
            // tldr: it has
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            merge(_existing: any[], incoming: any[]) {
              return incoming;
            },
          },
        },
      },
      Query: {
        // https://www.apollographql.com/docs/react/pagination/core-api/#defining-a-field-policy
        fields: {
          players: {
            // Cache results separately for these arguments
            keyArgs: [
              "$name",
              "$clubName",
              "$schoolCode",
              "$position",
              "$positionGroup",
              "$group",
              "$draftYear",
              "$sortCriteria",
              "$teamId",
              "$positionId",
              "$clubIds"
            ],
          },
          playersPaged: {
            // Cache results separately for these arguments
            keyArgs: [
              "$name",
              "$clubName",
              "$schoolCode",
              "$position",
              "$positionGroup",
              "$group",
              "$draftYear",
              "$sortCriteria",
              "$after",
              "$before",
              "$first",
              "$last",
            ],
          },
          draftDashboardPlayerListWithFilters: {
            // Cache results separately for these arguments
            keyArgs: [
              "$positionFilters",
              "$draftYear",
              "$showFavorites",
              "$sortCriteria",
              "$includePlayers",
              "$excludePlayers",
              "$searchText",
            ],
            // Concatenate the incoming list items with
            // the existing list items.
            merge: mergePageInfoQuery,
          },
        },
      },
    },
  }),
});

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: clientConfig.azureDirectory.clientId,
    authority: `https://login.microsoftonline.com/${clientConfig.azureDirectory.tenantId}`,
    // See:
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md#redirecturi-considerations
    // for why this is blank.html
    redirectUri: "/blank.html",
    postLogoutRedirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message) => {
        console.log(`[${level}]${message}`);
      },
      logLevel: LogLevel.Error,
    },
  },
});

msalInstance.addEventCallback((event: EventMessage) => {
  // Clear Apollo GQL cache on logout
  if (event.eventType === EventType.LOGOUT_SUCCESS) {
    client.clearStore();
  }

  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    msalInstance.setActiveAccount(payload.account);

    // If just logged-in, go ahead and reset every GQL query
    client.refetchQueries({
      include: "all",
    });

    Sentry.setUser({
      id: payload.account?.localAccountId,
      username: payload.account?.username,
    });
  }
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export default function Providers({ children }: { children: ReactNode }) {

  const account = msalInstance?.getActiveAccount();
  const oid = account?.localAccountId;
  const username = account?.username;

  useEffect(() => {
    if (username) {
      Sentry.setUser({
        id: oid,
        username,
      });
    }
  }, [username, oid]);

  const muiTheme = setThemeForUser();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CacheProvider value={clientSideEmotionCache}>
        <ThemeProvider theme={muiTheme}>
          <ApolloProvider client={client}>
            <QueryClientProvider client={queryClient}>
              <MsalProvider instance={msalInstance}>
                <IntercomProvider
                  appId={INTERCOM_APP_ID}
                  autoBoot
                  autoBootProps={{
                    name: username ? username : "Guest",
                  }}
                >
                  <PageContainer>{children}</PageContainer>
                </IntercomProvider>
              </MsalProvider>
            </QueryClientProvider>
          </ApolloProvider>
        </ThemeProvider>
        <ToastContainer theme="dark" autoClose={2500} hideProgressBar />
      </CacheProvider>
    </LocalizationProvider>
  );
}
