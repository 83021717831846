import { CDN_PATH } from "../../config/config";
import { getSchoolLogo } from "../../utils/schools";
import ImageWithFallback from "./ImageWithFallback";
import React from "react";

const missing_image = `${CDN_PATH}/missing_image.png`;

export function SchoolLogoImage(props: {
  schoolCode: string;
  height: number;
  priority?: boolean;
}) {
  const logo = getSchoolLogo(props.schoolCode);
  const width = props.height;

  if (props.priority) {
    <ImageWithFallback
      height={props.height}
      width={width}
      src={logo}
      alt="team logo"
      priority
      missingImage={missing_image}
    />;
  }

  return (
    <ImageWithFallback
      height={props.height}
      width={width}
      src={logo}
      alt="team logo"
      missingImage={missing_image}
    />
  );
}
