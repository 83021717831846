import { createTheme } from "@mui/material/styles";
import {
  ClubInformation,
  clubInformation,
} from "@sumer/shared/utils/clubStaticData";
import _ from "lodash";

declare module '@mui/material/styles' {
  interface Palette {
    gold: Palette['primary'];
  }

  interface PaletteOptions {
    gold?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides {
    gold: true;
  }
}

const designColors = {
  grey: {
    25: "#FCFCFD",
    50: "#F2F4F8",
    100: "#DDE1E6",
    200: "#C1C7CD",
    300: "#A2A9B0",
    400: "#878D96",
    500: "#697077",
    600: "#4D5358",
    700: "#343A3F",
    800: "#21272A",
    900: "#121619",
  },
  success: {
    // success
    light: "#F0FDF9",
    main: "#CCFBEF",
    dark: "#0E9384",
  },
  warning: {
    // orange
    light: "#FDEAD7",
    main: "#F38744",
    dark: "#932F19",
  },
  error: {
    // error
    light: "#FEF3F2",
    main: "#D92D20",
    dark: "#912018",
  },
  info: {
    // moss
    light: "#E6F4D7",
    main: "#CEEAB0",
    dark: "#669F2A",
  },
  gold: {
    light: "#FAD64D",
    main: "#E2C247",
    dark: "#C7AB3E",
    contrastText: '#FFFFFF',
  },
};
export const getClubIds = () => {
   return clubInformation.map((c) => c.clubId);
}
function lookupByClubCode(clubCode: string) {
  if (clubCode === "bal") clubCode = "BLT";
  const c = clubInformation.find((a) => a.clubCode === clubCode.toUpperCase());
  if (!c)
    throw new Error(
      `Club lookup failed ${clubCode} ${clubInformation.map((c) => c.clubCode)}`
    );
  return c;
}

export const getClubData = _.memoize(lookupByClubCode);

export const getClubAttributesFromConfig = (
  clubCode?: string
): ClubInformation | null => {
  if (clubCode === "DEFAULT") {
    return null;
  }
  return clubInformation.filter((c) => c.clubCode == clubCode)[0];
};

export const getClubAttributesFromUsername = (
  userEmail?: string
): ClubInformation => {
  if (userEmail && !userEmail.endsWith("@sumersports.com")) {
    const domain = userEmail.split("@")[1];
    return clubInformation.filter((c) => c.domain == domain)[0];
  }
  const testAsNE = clubInformation.find((c) => c.clubCode === "NE");
  if (!testAsNE) throw new Error("no NE theme");
  return testAsNE;
};

export const setThemeForUser = () => {
  return createTheme({
    palette: {
      ...designColors,
      primary: {
        main: designColors.grey[900],
      },
      secondary: {
        main: designColors.grey[600],
      },
    },
  });
};
